:root {
  --primary-color: #ea2845;
  --primary-accent-color: #ea2868;
  --primary-gradient: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--primary-accent-color) 100%
  );
}

html {
  position: relative;
  min-height: 450px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 17.5px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

* {
  outline: 0 !important;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

a:focus,
a:active {
  color: #fff;
}

.gradient-svg {
  position: absolute;
}

.primary-color {
  color: #ea2845;
}

.logo-wrapper svg,
.logo-wrapper img {
  max-width: 70px;
}

.nav-wrapper > li > a,
.nav-wrapper > li > span {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.nav-wrapper > li:not(.social-icon) {
  margin-left: 25px;
}

.nav-wrapper .arrow {
  font-size: 12px;
  margin-left: 4px;
}

.nav-wrapper > li > span:hover > .arrow {
  color: #ea2845;
  cursor: pointer;
}

.nav-wrapper > li > a:hover,
.nav-wrapper > li > span:hover,
.sub-nav-wrapper li a:hover {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.nav-wrapper > li > span:hover > .new {
  -webkit-text-fill-color: #fff;
}

.nav-wrapper > li > span > * {
  vertical-align: middle;
  display: inline-block;
}

.nav-wrapper .text {
  margin-left: 4px;
}

.nav-wrapper > li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.nav-wrapper a:hover span:not(.new) {
  color: #ea2845;
  cursor: pointer;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-wrapper a:hover svg path {
  fill: url(#primary-gradient);
}

.nav-wrapper li {
  display: inline-block;
  vertical-align: middle;
}

.nav-wrapper .icon {
  margin-left: 27px;
  font-size: 22px;
}

.nav-wrapper > li:hover > .sub-nav-outlet {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.sub-nav-outlet {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: -25px;
  left: 6px;
  padding-top: 6px;
  transition: all 100ms ease-in;
  transform: translateY(-10px);
}

.sub-nav-wrapper {
  background: #0f1114;
  border-radius: 8px;
  padding: 24px;
  text-align: left;
}

.sub-nav-wrapper li {
  display: block;
}

.navbar-sticky .sub-nav-wrapper {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
}

.sub-nav-wrapper li {
  list-style: none;
  line-height: 14px;
}

.sub-nav-wrapper li a {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 0;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.navbar-sticky .sub-nav-wrapper li a {
  color: #090909;
}

.sub-nav-wrapper ul {
  padding: 0;
  margin: 0 0 16px;
}

.sub-nav-wrapper ul:last-of-type {
  margin-bottom: 0;
}

.sub-nav-wrapper .nav-category {
  font-size: 11px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-bottom: 4px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.navbar-sticky .sub-nav-wrapper .nav-category {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

.mobile-nav-icon {
  position: relative;
  margin: 30px 0;
  display: none;
}

@media (max-width: 1020px) {
  .mobile-nav-icon {
    display: block;
  }
}

.mobile-nav-icon span {
  display: block;
  width: 24px;
  height: 2px;
  background: #fff;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.mobile-nav-icon span::before,
.mobile-nav-icon span::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #fff;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.mobile-nav-icon span::before {
  top: -8px;
}

.mobile-nav-icon span::after {
  bottom: -8px;
}

.mobile-nav-icon:hover {
  cursor: pointer;
}

.mobile-nav-icon:hover span,
.mobile-nav-icon:hover span::before,
.mobile-nav-icon:hover span::after {
  background: var(--primary-gradient);
}

.mobile-nav-icon .fill {
  height: 24px;
  width: 24px;
  margin-top: -12px;
  position: absolute;
}

.mobile-nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #111111;
  z-index: 1000000;
  padding: 24px 15px;
  display: flex;
  flex-direction: column;
  display: none;
  overflow-y: auto;
}

@media (max-width: 1020px) {
  .mobile-nav.open {
    display: flex;
  }
}

body.mobile-nav-open {
  overflow: hidden;
}

.mobile-nav-logo img {
  width: 70px;
}

.mobile-nav-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav-close span {
  position: relative;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
}

.mobile-nav-close span::before,
.mobile-nav-close span::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #fff;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.mobile-nav-close span:hover::before,
.mobile-nav-close span:hover::after {
  background: var(--primary-gradient);
}

.mobile-nav-close span::before {
  transform: rotate(45deg);
  top: 10px;
}

.mobile-nav-close span::after {
  transform: rotate(-45deg);
  top: 10px;
}

.mobile-nav ul {
  padding: 0;
  margin: 32px 0;
}

.mobile-nav li {
  list-style: none;
  line-height: 36px;
}

.mobile-nav li a {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.mobile-nav li a span {
  vertical-align: middle;
}

.mobile-nav .new {
  margin-left: 6px;
  margin-right: 0;
}

.mobile-nav li a:hover span:not(.new) {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobile-nav .btn-primary {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  padding: 14px;
  min-width: 100%;
}

.mobile-nav-footer {
  margin-top: auto;
}

.d-inline-block {
  display: inline-block;
}

.top-wrapper {
  padding-top: 30px;
}

@media (max-width: 1020px) {
  .top-wrapper {
    padding-top: 24px;
  }
}

.m-l-30 {
  margin-left: 30px !important;
}

.page-header {
  background-image: url("./img/header-1.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #090909;
  background-attachment: fixed;
  color: #fff;
  min-height: 700px;
}

@media (max-width: 1400px) {
  .page-header {
    background-image: url("./img/header-1-alt.jpg");
  }
}

.main-wrapper {
  padding-top: 140px;
}

.main-wrapper h1 {
  font-weight: 600;
  font-size: 55px;
  margin: 0 0 5px;
  line-height: 75px;
}

.main-wrapper h4 {
  font-weight: 500;
  max-width: 600px;
  margin: 0;
  font-size: 18px;
  line-height: 32px;
}

.buttons-wrapper {
  padding: 60px 0;
}

.buttons-wrapper .btn:first-child {
  margin-right: 20px;
}

.buttons-wrapper .btn .fa-github {
  margin-right: 6px;
  font-size: 18px;
  vertical-align: middle;
}

.btn {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 16px 28px;
  min-width: 230px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
}

.btn-primary {
  background: var(--primary-gradient);
  color: #fff;
  position: relative;
  overflow: hidden;
}

.btn-primary::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: var(--primary-gradient);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 2;
}

.btn-primary span {
  z-index: 3;
  position: relative;
}

.btn-primary:hover {
  background: transparent;
}

.btn-primary:hover span,
.btn-primary:active span,
.btn-primary:focus span {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-secondary {
  color: #fff;
  position: relative;
}

.btn-secondary::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: #fff;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 2;
}

.btn-secondary:hover {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-secondary:hover::before {
  background: var(--primary-gradient);
}

.btn img {
  margin-right: 5px;
}

.btn span {
  vertical-align: middle;
}

.course-info-wrapper {
  background: #f1f3f3;
  padding: 100px 0;
  font-weight: 500;
  line-height: 30px;
}

.course-info-wrapper h2 {
  font-size: 34px;
  letter-spacing: -0.2px;
  line-height: 45px;
}

.course-info-wrapper p:not(.large-text) {
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #3e3e3e;
}

.course-info-wrapper .large-text {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.2px;
}

.course-info-wrapper a {
  font-size: 15px;
  letter-spacing: -0.2px;
  color: #ed1543;
}

.course-info a:hover {
  color: #d01940;
}

.course-info a:hover svg {
  transform: translateX(5px);
  color: #d01940;
}

.course-info a svg {
  font-size: 15px;
  margin-left: 4px;
  vertical-align: middle;
  transition: all 150ms ease-in-out;
}

.course-info-wrapper p.release-date {
  color: #111;
}

.course-info-wrapper p.release-date strong {
  font-weight: 600;
}

.course-info-wrapper ul {
  display: block;
  list-style: none;
  margin-top: 30px;
  padding: 0;
}

.course-info-wrapper li {
  line-height: 45px;
  font-size: 15px;
}

.course-info-wrapper ul span {
  background: rgba(19, 186, 18, 0.15);
  color: #1dd81c;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  font-size: 12px;
  vertical-align: middle;
  margin-right: 15px;
}

.course-info-wrapper li span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.lesson-icon {
  margin-top: 7px;
  margin-left: 5px;
}

.highlights-wrapper,
.discover-wrapper {
  background-color: #f1f3f3;
  background: linear-gradient(360deg, #f1f1f1, #f9f9f9);
  text-align: center;
}

.highlights-wrapper {
  padding: 100px 0;
}

.discover-wrapper {
  padding: 100px 0;
}

.highlights-wrapper .heading h4,
.discover-wrapper .heading h4,
.faq-wrapper .heading h4 {
  font-size: 36px;
  margin: 0;
  line-height: 60px;
}

.highlights-wrapper .heading p,
.discover-wrapper .heading p,
.faq-wrapper .heading p {
  font-weight: 500;
  max-width: 700px;
  margin: 15px auto 50px;
  color: #000;
  font-size: 16px;
  line-height: 30px;
}

.faq-wrapper {
  background: #e4e4e4;
  padding: 100px 0;
  text-align: center;
}

.faq-questions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.faq-question-wrapper {
  flex: 50%;
  max-width: 50%;
  padding: 15px;
}

.faq-question-wrapper--wide {
  flex: 100%;
  max-width: 100%;
}

.faq-question {
  background: #fbfbfb;
  padding: 40px 30px;
  border-radius: 5px;
  height: 100%;
}

.faq-question h5 {
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 15px;
}

.faq-question p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #636363;
}

.faq-question p strong {
  color: #0c0c0c;
}

.faq-question a {
  color: #e0244e;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.heading a {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading a:hover {
  color: #111;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: none;
}

.discover-wrapper .heading a:hover {
  color: #111;
}

.highlights-wrapper .icon-wrapper {
  width: 70px;
  height: 75px;
  margin: 0 auto;
  display: block;
}

.highlights-wrapper h5 {
  font-weight: 600;
  color: #ea2845;
  margin: 0 0 10px;
}

.extension-card--bundle h5 {
  color: #c6219b;
}

.highlights-wrapper p {
  font-size: 16px;
  font-weight: 500;
  max-width: 300px;
  margin: 20px auto;
  line-height: 30px;
}

.highlight-block .extension-card {
  margin-top: 50px;
  transition: all 120ms ease-in;
}

.highlight-block:not(.highlight-block--coming-soon) {
  cursor: pointer;
}
.highlight-block--coming-soon .extension-card {
  background: #e2e2e2;
  filter: grayscale(1);
}

.highlight-block:not(.highlight-block--coming-soon):hover .extension-card {
  background-color: #fafafa;
  box-shadow: 0px 4px 20px #c2c2c2;
}

.email-wrapper {
  background: #111;
  text-align: center;
  color: #fff;
  padding: 80px 10px;
}

.email-wrapper h2 {
  font-size: 32px;
  margin: 0 0 10px;
}

.email-wrapper p {
  margin: 0;
  line-height: 30px;
}

.email-wrapper .btn {
  display: inline-block;
  margin-top: 30px;
}

.email-wrapper .mail {
  color: #ed1543;
  font-size: 36px;
  font-weight: 500;
}

.btn-black {
  color: #fff;
  background: #090909;
  border: 2px solid #090909;
}

.btn-black span {
  color: #969696;
  margin: 0 3px;
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
}

.btn-black:hover {
  background: #ea2845;
  border: 2px solid #ea2845;
  color: #fff;
}

.btn-black:hover span {
  color: #fff;
}

.page-footer {
  background-color: #f1f3f3;
  text-align: center;
  color: #090909;
  padding: 60px 0;
}

.page-footer .icon {
  color: #c2c3c3;
  font-size: 24px;
  margin: 0 10px;
}

.page-footer .icon:hover {
  color: #090909;
}

.page-footer p {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: #a5a5a5;
}

.page-footer p a {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.page-footer p a:hover {
  background: #111;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-sticky {
  position: fixed;
  z-index: 100000;
  left: 0;
  right: 0;
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
  padding: 8px 0;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  -webkit-transform: translate3d(0, -100%, 0);
  transition: all 180ms ease-in-out;
  -ms-transition: all 180ms ease-in-out;
  -webkit-transition: all 180ms ease-in-out;
}

.navbar-sticky.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.navbar-sticky .logo-wrapper img {
  width: 40px;
}

.navbar-sticky .nav-wrapper {
  margin: 5px 0 0;
  width: calc(100% - 50px);
  text-align: right;
  vertical-align: middle;
}

.navbar-sticky .nav-wrapper > li > a,
.navbar-sticky .nav-wrapper > li > span {
  color: #090909;
  font-size: 14px;
}

.navbar-sticky .nav-wrapper a:hover {
  color: #ea2845;
}

.navbar-sticky .nav-wrapper .icon {
  margin-left: 12px;
  font-size: 16px;
}

.enterprise-wrapper {
  background-image: url("./img/support-alt2.jpg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-color: #d11144;
  height: 500px;
  overflow: hidden;
}

.enterprise-column {
  width: 60%;
  float: right;
  padding: 90px 0;
  color: #fff;
}

.enterprise-column h2 {
  font-size: 32px;
  margin-top: 0;
}

.enterprise-column p {
  font-weight: 500;
  line-height: 32px;
}

.enterprise-column .btn {
  min-width: 200px;
  margin-top: 25px;
  font-weight: 600;
  background: #fff;
  border-color: #fff;
  color: #ed1543;
  -webkit-text-fill-color: unset !important;
  -webkit-background-clip: unset !important;
}

.enterprise-column .btn:hover {
  background: transparent;
  color: #fff;
}

.enterprise-column .btn:hover::before {
  background: #fff;
}

.newsletter-wrapper {
  background: #e8e8e8;
  padding: 45px 0 35px;
  overflow: hidden;
  position: relative;
}

.newsletter-wrapper h3 {
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 4px;
  color: #111;
}

.newsletter-wrapper p {
  font-size: 14px;
  margin: 0;
  color: #7b7b7b;
  font-weight: 500;
}

.newsletter-wrapper hgroup,
.newsletter-form {
  display: inline-block;
  vertical-align: middle;
}

.newsletter-form {
  text-align: right;
  width: 40%;
  position: relative;
  margin-top: 5px;
}

.newsletter-wrapper hgroup {
  width: 60%;
  float: left;
  position: relative;
}

.newsletter-form .form-control {
  border: 0;
  font-size: 13px;
  height: 40px;
  padding: 10px 20px;
  background: #fdfdfd;
  width: calc(100% - 70px);
  box-shadow: -2px 2px 3px gainsboro;
  border-radius: 3px;
  max-width: 100%;
  vertical-align: middle;
}

.newsletter-form button {
  background: var(--primary-color);
  background: var(--primary-gradient);
  padding: 13px 18px;
  border: 0;
  color: #fff;
  margin-left: -45px;
  font-size: 14px;
  vertical-align: middle;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: none;
}

.newsletter-form button:hover,
.newsletter-form button[disabled] {
  background: #111111;
}

.newsletter-form button .icon-success {
  display: none;
}

.newsletter-form .btn-success .fa-paper-plane {
  display: none;
}

.newsletter-form .btn-success[disabled] {
  background: #d3e6d4;
  color: #1dd81c;
  pointer-events: none;
}

.newsletter-form .btn-success .icon-success {
  display: block;
}

.newsletter-wrapper .envelope-bg {
  font-size: 180px;
  position: absolute;
  margin-top: -50px;
  margin-left: 60px;
  color: #dedede;
  z-index: 0;
  transform: rotate(-30deg);
}

.btn-watch {
  background: var(--primary-gradient);
  border: 0;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 1px 8px;
  line-height: 21px;
  float: right;
  margin: 4px 15px 0 0;
  cursor: pointer;
}

.btn-watch svg {
  margin-right: 5px;
  font-size: 9px;
}

.extension-modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100009;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.extension-modal-container.opened {
  visibility: visible;
  opacity: 1;
}

.extension-modal-container .close-icon {
  font-size: 28px;
  color: grey;
  position: absolute;
  top: 15px;
  right: 35px;
  cursor: pointer;
}

.extension-modal-container .close-icon:hover {
  color: #111;
}

.extension-modal-container .checkout-wrapper iframe,
.extension-modal-container .checkout-wrapper .video-preview-placeholder {
  margin-top: 25px;
}

.extension-modal {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1250px;
  max-width: 95%;
  background: #fff;
  margin-top: 20px;
  padding: 0;
  display: flex;
  align-items: stretch;
}

.extension-modal-content {
  overflow-y: scroll;
  display: flex;
  align-items: stretch;
  max-height: 90%;
}

.extension-modal .curriculum-block {
  background: #f9f9f9;
}

.extension-info {
  padding: 25px 35px;
}

.extension-info blockquote {
  margin: 25px 0 0 0;
  background: #f7f7f7;
  padding: 15px 20px;
}

.extension-info blockquote em {
  font-size: 16px;
  font-style: normal;
  color: #3e3e3e;
}

.extension-info blockquote a {
  font-size: 16px;
}

.extension-modal .checkout-wrapper {
  max-width: initial;
  height: auto;
  float: none;
  background: #f4f4f4;
  min-width: 500px;
  border-radius: 0;
}

@media (max-width: 1070px) {
  .extension-info {
    padding: 15px 20px;
  }
  .extension-modal,
  .extension-modal-content {
    display: block;
  }
  .extension-modal .checkout-wrapper {
    min-width: 100%;
    margin-bottom: 0;
  }
  .extension-modal .teachers-wrapper {
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.extension-modal .curriculum-wrapper {
  padding-bottom: 45px;
}

.extension-modal.course-info-wrapper h2 {
  margin-top: 10px;
}

.video-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1170px;
  min-height: 675px;
  max-width: 95%;
  height: 100%;
}

.video-modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100010;
  display: none;
}

.video-modal-container.opened {
  display: block;
}

.companies-tiles {
  margin: 70px auto;
}

.companies-tiles a {
  text-align: center;
  height: 60px;
  width: 20%;
  float: left;
  margin: 30px 0;
  position: relative;
}

.companies-tiles img {
  filter: grayscale(1);
  opacity: 0.5;
  max-width: 135px;
  max-height: 80px;
}

.companies-tiles img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.companies-tiles a img {
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
}

@media (max-width: 1440px) {
  .enterprise-wrapper {
    background-position: -350px bottom;
  }
  .enterprise-column {
    width: 70%;
    padding: 70px;
  }
}

.checkout-wrapper {
  background: #fff;
  border-radius: 10px;
  width: 440px;
  padding: 30px 20px;
  max-width: 100%;
  float: right;
}

.teachers-wrapper {
  margin: 2.1em 0;
}

.teacher-meta {
  margin: 0;
  width: 50%;
  float: left;
}

.teacher-image,
.teacher-text {
  display: block;
  text-align: center;
  width: 100%;
}

.teacher-image {
  width: 45px;
  margin: 0 auto 1em;
}

.teacher-image img {
  width: 100%;
}

.teacher-text {
  font-size: 14px;
  line-height: 22px;
}

.teacher-text h3 {
  margin: 0;
}

.teacher-text p {
  margin: 0;
  font-size: 15px !important;
}

.checkout-wrapper iframe,
.video-preview-placeholder {
  margin: 0 auto;
  display: block;
  border-radius: 6px;
  max-width: 100%;
}

.video-preview-placeholder {
  max-width: 380px;
}
.checkout-wrapper .price {
  text-align: center;
  font-size: 35px;
  color: #000;
  font-weight: 600;
  margin: 1em 0 1.5em;
  position: relative;
}

@media (max-width: 420px) {
  .checkout-wrapper .price {
    font-size: 28px;
  }
}

.checkout-wrapper .btn {
  margin: 0 auto;
  display: block;
  padding: 12px 20px;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 240px;
  width: 100%;
}

.checkout-wrapper .original-price {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  text-decoration: line-through;
  vertical-align: middle;
  position: relative;
}

.checkout-wrapper .original-price::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: -2px;
  height: 1px;
  background: rgb(138, 138, 138);
  top: 50%;
}

.checkout-wrapper .current-price {
  margin: auto 0.5em;
  vertical-align: middle;
}

@media (max-width: 420px) {
  .checkout-wrapper .current-price {
    margin: auto 0.25em;
  }
}

.checkout-wrapper .discount,
.extra-bundle-discount,
.extra-pre-order-discount {
  background: var(--primary-gradient);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  vertical-align: middle;
}

.extra-bundle-discount {
  background: #c6219b;
  font-weight: 700;
}

.extra-pre-order-discount {
  background: #27ccb3;
  font-weight: 700;
}

.checkout-wrapper .discount-note {
  display: block;
  text-align: center;
  font-size: 15px;
  max-width: 260px;
  margin: 20px auto 0;
  line-height: 24px;
  color: #3e3e3e;
}

.checkout-wrapper .vat {
  font-size: 13px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 34px;
  color: #9c9c9c;
}

.checkout-wrapper .vat img {
  margin-left: 3px;
}

.checkout-wrapper .discount-note strong,
.checkout-wrapper .discount-note a {
  color: #27ccb3;
  font-weight: 600;
  position: relative;
}

.checkout-wrapper .discount-note a:hover::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background: #27ccb3;
}

.checkout-wrapper .video-title {
  text-align: center;
  font-size: 14px;
  color: gray;
  display: block;
  margin-bottom: 0.5em;
}

.supported-payments {
  text-align: center;
  margin: 0.5em 0;
  opacity: 0.7;
  filter: grayscale(1);
}

.supported-payments:hover {
  filter: grayscale(0.1);
  opacity: 1;
}

.supported-payments img {
  margin: 0 2px;
}

.btn-green {
  background-color: #27ccb3;
  border: 2px solid #27ccb3;
  font-weight: 600;
  font-size: 16px;
}

.btn-green:hover {
  background: transparent;
  color: #27ccb3;
}

.btn-green .icon {
  margin-left: 0.4em;
}

.course-info {
  padding-right: 3em;
}

.curriculum-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.curriculum-block {
  background: #fff;
  border-top: 2px solid #f1f3f3;
  overflow: hidden;
}

.curriculum-block--all-in-one {
  background: var(--primary-gradient);
  color: #fff;
}

.curriculum-block--all-in-one .extension-heading .extension-label {
  background: #fff;
  color: var(--primary-accent-color);
}

.curriculum-block--all-in-one .extension-heading:hover {
  background: var(--primary-accent-color)
}

.curriculum-block--all-in-one .extension-heading .price {
  color: #fff;
}

.curriculum-block--all-in-one  .extension-heading h5 svg, .card-header h4 svg {
  color: rgba(255, 255, 255, 0.7);
}


.curriculum-block .category-items {
  display: none;
}

.curriculum-block.opened .category-items {
  display: block;
}

.curriculum-block.opened {
  margin: 18px 0;
  border-top: 0;
}

.curriculum-block:first-of-type {
  margin-top: 0;
}

.curriculum-block.opened .category-heading {
  background: var(--primary-gradient);
  color: #fff;
}

.curriculum-block.opened .category-heading svg:not(.fa-play) {
  transform: rotate(180deg);
}

.curriculum-block:last-of-type {
  margin-bottom: 0;
}

.curriculum-block .category-heading,
.curriculum-block .extension-heading {
  padding: 12px 20px;
}

.curriculum-block .category-heading svg {
  color: #b3b3b3;
  margin-left: 8px;
  margin-top: 7px;
  float: right;
}

.curriculum-block.opened {
  border-radius: 10px;
}

.curriculum-block.opened + .curriculum-block {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.curriculum-block.opened-below {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.curriculum-block.opened .category-heading svg {
  color: #fff;
}

.curriculum-block .category-heading:not(.category-heading--no-expand):hover {
  cursor: pointer;
}

.curriculum-block:not(.opened) .category-heading:hover * {
  color: #ea2945;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.curriculum-block h5 {
  margin: 0;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

.curriculum-block span {
  color: #9c9c9c;
  font-size: 1.4rem;
  float: right;
  margin-right: 12px;
}

.curriculum-block .category-heading span {
  color: #797979;
}

.curriculum-block.opened .category-heading span {
  color: #fff;
}

.curriculum-block .video-item {
  padding: 8px 20px;
}

.curriculum-block .video-item:first-of-type {
  padding-top: 12px;
}

.curriculum-block .video-item:last-of-type {
  padding-bottom: 12px;
}

.video-item:nth-of-type(even) {
  background: #efefef52;
}

.curriculum-block .category-items h6 {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #676b71;
}

.curriculum-block .category-items .preview-video-title {
  color: #111;
}

.curriculum-block .category-items span {
  margin-right: 0;
}

.extension-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
}

.extension-card .card-image {
  background: #101010;
  padding: 40px 10px 25px;
  position: relative;
}

.extension-card .card-image--bundle {
  background: #200031;
}

.second-ext-icon {
  transform: scale(0.8) translateY(-5px);
}

.extension-card .card-body {
  margin-top: 3px;
}

.extension-card .card-body svg {
  color: #e0244e;
  margin: 0 5px 0 10px;
}

.extension-card:not(.extension-card--bundle) .card-body svg path {
  fill: url(#primary-gradient);
}

.extension-card--bundle .card-body svg {
  color: #c6219b;
}

.extension-card .triangle-1 {
  position: absolute;
  left: 50px;
  top: 80px;
}

.extension-card .triangle-2 {
  position: absolute;
  right: 40px;
}

.highlight-block:nth-of-type(1) .triangle-1 {
  transform: rotate(45deg);
}

.highlight-block:nth-of-type(1) .triangle-2 {
  transform: rotate(218deg);
}

.highlight-block:nth-of-type(2) .triangle-1,
.highlight-block:nth-of-type(6) .triangle-1 {
  transform: rotate(125deg);
  top: 60px;
}

.highlight-block:nth-of-type(2) .triangle-2,
.highlight-block:nth-of-type(6) .triangle-2 {
  transform: rotate(188deg);
  top: 70px;
}

.highlight-block:nth-of-type(3) .triangle-1 {
  transform: rotate(100deg);
}

.highlight-block:nth-of-type(3) .triangle-2 {
  transform: rotate(277deg);
}

.highlight-block:nth-of-type(4) .triangle-1 {
  transform: rotate(55deg);
  top: 60px;
}

.highlight-block:nth-of-type(4) .triangle-2 {
  transform: rotate(205);
  top: 70px;
}

.extension-card .card-body svg:first-of-type {
  margin-left: 0;
}

.featured-lesson {
  margin-top: 60px;
  max-width: 100%;
  height: 100%;
  max-height: 670px;
}

@media (max-width: 720px) {
  .featured-lesson {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    max-height: 400px;
  }
}

@media (max-width: 480px) {
  .featured-lesson {
    max-height: 200px;
  }
}

.extension-heading:not(.extension-heading--coming-soon) {
  position: relative;
  cursor: pointer;
}

.extension-heading:not(.extension-heading--coming-soon):not(.extension-heading--all-in-one):hover {
  background-color: #fafafa;
}

.extension-heading .extension-label {
  color: #fff;
  font-size: 11px;
  float: none;
  position: static;
  left: auto;
  top: auto;
  margin-right: 5px;
  padding: 3px 5px;
}

.extension-heading h5 svg,
.card-header h4 svg {
  color: #afafaf;
  margin-left: 4px;
}

.card-header h4 svg {
  font-size: 15px;
}

.extension-heading .price {
  color: #27ccb3;
  font-size: 16px;
  font-weight: 600;
  margin-top: 2px;
}

.extension-heading .original-price {
  text-decoration: line-through;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 600;
}

.extension-heading--all-in-one .original-price {
  color: rgba(255, 255, 255, 0.55);
}

.extension-heading--coming-soon {
  background: #eaeaea;
}

.extension-label {
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 2px;
  left: 15px;
  top: 15px;
}

.extension-label--bundle {
  background: #c6219b;
}

.extension-label--pre-order {
  background: #27ccb3;
}

.extension-label--early-pre-order {
  background: var(--primary-gradient);
}

.extension-label--coming-soon {
  background: var(--primary-gradient);
}

.extension-card .card-header {
  margin-top: 1.5em;
  padding: 0 1.5em;
}

.extension-card h4 {
  margin: 0;
}

.extension-card p {
  margin: 0 auto;
  font-size: 15px;
}

.extension-price {
  font-size: 22px;
  padding: 5px 20px 15px;
  font-weight: 600;
  color: #27ccb3;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.parity-discount-wrapper,
.preorder-discount-wrapper {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background: #0a0a0a;
  transition: all 200ms ease-in;
  display: none;
}

.parity-discount-wrapper.visible,
.preorder-discount-wrapper.visible {
  display: block;
}

.parity-discount-wrapper span,
.preorder-discount-wrapper span {
  background: var(--primary-gradient);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px;
  vertical-align: baseline;
  margin: 0 4px;
}

.parity-discount-wrapper p,
.preorder-discount-wrapper p {
  margin: 0;
  padding: 20px 0;
  line-height: 25px;
}

.parity-discount-wrapper img {
  margin: 0 2px;
}

.parity-discount-wrapper a,
.preorder-discount-wrapper a {
  color: #ea2945;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 3px;
}

.parity-discount-wrapper a:hover,
.preorder-discount-wrapper a:hover {
  color: #fff;
}

.discount-tooltip {
  display: none;
}

.discount-tooltip.visible {
  display: block;
}

.tooltip {
  position: relative;
  padding: 20px;
  text-align: center;
  color: #fff;
  background: #0c0c0c;
  border-radius: 4px;
  width: 80%;
  margin: 0 auto 25px;
}

.tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0c0c0c;
  left: 50%;
  top: -7px;
  transform: translateX(-50%);
}

.course-info-wrapper .tooltip p {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}

.course-info-wrapper .tooltip a {
  color: #27ccb3;
  font-weight: 700;
  font-size: 14px;
}

.course-info-wrapper .tooltip span {
  background: #27ccb3;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  padding: 3px 4px;
  border-radius: 4px;
  vertical-align: middle;
}

.course-info-wrapper .tooltip a:hover {
  color: #fff;
}

@media (max-width: 1020px) {
  .newsletter-wrapper hgroup,
  .newsletter-form {
    width: 50%;
  }

  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .enterprise-wrapper {
    background-position: -400px bottom;
  }

  .enterprise-column {
    width: 100%;
    text-align: center;
  }

  .main-wrapper {
    text-align: center;
  }

  .main-wrapper h4 {
    margin-right: auto;
    margin-left: auto;
  }

  .buttons-wrapper .btn:first-child,
  .buttons-wrapper .btn {
    margin: 0 10px;
  }

  .course-info-wrapper ul {
    padding: 0;
    margin-top: 30px;
  }

  .course-info {
    padding-right: 15px;
  }

  .checkout-wrapper {
    float: none;
    margin-bottom: 5em;
    width: 100%;
  }
}

@media (max-width: 920px) {
  .highlight-block {
    margin-bottom: 10px;
  }

  .highlight-block:last-of-type {
    margin-bottom: 0;
  }

  .hidden-sm {
    display: none !important;
  }

  .page-header {
    background-position: 35% bottom;
  }

  .navbar-sticky {
    display: none;
  }

  .companies-tiles a {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .extension-card {
    height: auto;
  }

  .extension-price {
    position: relative;
  }
}

@media (max-width: 680px) {
  .enterprise-wrapper {
    height: auto;
    background: #ed1543;
  }
  .enterprise-column {
    padding-left: 0;
    padding-right: 0;
  }
  .newsletter-wrapper hgroup,
  .newsletter-form {
    width: 100%;
  }
  .newsletter-wrapper hgroup {
    float: none;
    text-align: center;
  }
  .newsletter-form {
    margin-top: 30px;
    text-align: center;
  }
  .newsletter-form .form-control {
    margin-left: 0;
  }
  .newsletter-wrapper .envelope-bg {
    font-size: 280px;
  }
  .faq-question-wrapper {
    flex: 100%;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .hidden-xs {
    display: none !important;
  }

  .buttons-wrapper .btn,
  .buttons-wrapper .btn:first-child {
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .main-wrapper {
    padding-top: 100px;
  }

  .company-logo {
    margin: 30px auto 0;
    display: block;
  }

  .buttons-wrapper .btn:first-of-type {
    margin-bottom: 20px;
  }

  .course-info-wrapper .large-text {
    font-size: 18px;
    line-height: 32px;
  }
  .curriculum-block span {
    float: none;
    margin-right: 0;
  }
  .curriculum-block .category-heading,
  .curriculum-block .extension-heading {
    line-height: 24px;
  }
  .curriculum-block .original-price {
    display: none;
  }
  .curriculum-block .category-heading span {
    display: block;
  }
  .btn-watch {
    display: inline-block;
    margin: 0px 0 0 10px;
    float: none;
  }
}

@media (max-width: 420px) {
  .companies-tiles a {
    width: 100%;
  }
}

.m-t-40 {
  margin-top: 40px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.new {
  color: #fff !important;
  background: var(--primary-gradient);
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 2px;
  margin-right: 2px;
  vertical-align: middle;
}
